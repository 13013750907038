import StoreItemsModule from "@/config/store/StoreItemsModule"

import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import actionsBuild from "./actions"
import mutationsBuild from "./mutations"
import { commonStateBuild, commonGetters } from "../shared"

const BASE_URI = "/inventory_groups_car_classes"
const store = new StoreItemsModule({
  baseURI: BASE_URI,
  presetActions: ["fetch"],
  withFilters: true,
  withPagination: true,
  withSorting: true
})

store.mergeState(commonStateBuild())

store.mergeMutations(mutationsBuild())

store.mergeGetters(commonGetters)

const { UPDATE_ITEMS } = actionsBuild({ baseURI: BASE_URI })

store.mergeActions({ UPDATE_ITEMS }, withLoading)

export default store
