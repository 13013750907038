<template lang="pug">
  .container-fluid.inventory-groups-car-class-setting
    AppOverlayLoader(:state="inventoryGroupsLoading")
    TopBar(
      :hidden-elements="['add-new-button', 'download-button']"
      :allow-save="!isEmpty(updatedInventoryGroups)"
      @search="handleSearch"
      @save="handleSave"
    )
    MatchingTable(
      :inventory-groups="inventoryGroups"
      :sorting-data="sorting"
      @change="changeInventoryGroup"
      @sorting="handleSorting"
    )
    AppPagination(
      :current-page="pagination.current_page"
      :per-page="pagination.per_page"
      :total="pagination.total_count"
      @change-pagination-data="handleChangePagination"
    )
</template>

<script>
  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withConfirmation from "@/mixins/withConfirmation"
  import withToyotaOrganizationsMountedOrder from "@/mixins/withToyotaOrganizationsMountedOrder"

  // store modules
  import inventoryGroupsCarClassMatchingModule from "@/config/store/matching/inventory_groups_car_classes"

  // misc
  import { isEmpty } from "lodash-es"

  const inventoryGroupsCarClassMatchingMixin = withStoreModule(inventoryGroupsCarClassMatchingModule, {
    resetState: true,
    name: "inventoryGroupsCarClassMatching",
    readers: {
      inventoryGroups: "items",
      pagination: "pagination",
      sorting: "sorting",
      inventoryGroupsLoading: "loading"
    },
    getters: { updatedInventoryGroups: "itemsUpdated" },
    mutations: {
      setInventoryGroup: "SET_ITEM_BY_ID",
      setPagination: "SET_PAGINATION_DATA",
      setFilters: "SET_FILTERS",
      setSorting: "SET_SORTING"
    },
    actions: {
      fetchInventoryGroups: "FETCH_ITEMS",
      updateInventoryGroups: "UPDATE_ITEMS"
    }
  })

  export default {
    components: {
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader"),
      TopBar: () => import("@/components/layout/TopBar"),
      MatchingTable: () => import("./MatchingTable"),
      AppPagination: () => import("@/components/elements/AppPagination")
    },

    mixins: [inventoryGroupsCarClassMatchingMixin, withConfirmation, withToyotaOrganizationsMountedOrder],

    mounted() {
      this.fetchInventoryGroups()
    },

    methods: {
      isEmpty,

      changeInventoryGroup(inventoryGroup) {
        this.setInventoryGroup(inventoryGroup)
      },

      handleChangePagination(pagination) {
        this.withConfirmOnChanges(() => {
          this.setPagination({ ...this.pagination, ...pagination })
          this.fetchInventoryGroups()
        })
      },

      handleSearch(filters) {
        this.withConfirmOnChanges(() => {
          this.setFilters(filters)
          this.fetchInventoryGroups()
        })
      },

      handleSorting(sorting) {
        this.withConfirmOnChanges(() => {
          this.setSorting(sorting)
          this.fetchInventoryGroups()
        })
      },

      withConfirmOnChanges(handler) {
        this.$conditionalConfirm({
          useConfirm: !isEmpty(this.updatedInventoryGroups),
          handler
        })
      },

      handleSave() {
        this.updateInventoryGroups(this.updatedInventoryGroups)
      }
    }
  }
</script>

<style lang="sass" scoped>
  .inventory-groups-car-class-setting
    position: relative

    ::v-deep
      .top-bar
        .app-search
          margin-left: 0

        .save-button
          .app-button
            margin-right: 0
</style>
