import { map } from "lodash-es"
import { api } from "@/config"

const selectedCarClassesParams = carClasses => {
  return map(carClasses, ({ id, order }) => ({ car_class_id: id, order }))
}

const inventoryGroupParams = inventoryGroups => {
  return map(inventoryGroups, ({ id, selected_car_classes }) => {
    return {
      id,
      car_classes: selectedCarClassesParams(selected_car_classes)
    }
  })
}

export default ({ baseURI }) => ({
  UPDATE_ITEMS: async ({ commit, state: { filters, sorting, pagination } }, inventoryGroups) => {
    const params = {
      filters,
      sorting,
      pagination,
      inventory_groups: inventoryGroupParams(inventoryGroups)
    }

    const response = await api.put(baseURI, params)
    const { items, meta } = response.data.data

    commit("SET_ITEMS", items)
    commit("SET_PAGINATION_DATA", meta.pagination)
  }
})
